<template>
  <component :is="'b-card'">
    <b-tabs pills>
      <b-tab :title="$t('admin.products.tabs.general.label')">
        <b-overlay
          :show="Object.keys(catalog.title).length === 0"
          rounded="sm"
        >
          <b-form-group
            :label="$t('form.indexing_status.label')"
            label-for="indexing_status"
            :state="errors && errors.indexing_status ? false : null"
          >
            <v-select
              id="indexing_status"
              v-model="catalog.indexing_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="indexing_status"
            />
            <b-form-invalid-feedback v-if="errors && errors.indexing_status">
              {{ errors.indexing_status[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('form.has_filter.label')"
            label-for="has_filter"
            :state="errors && errors.has_filter ? false : null"
          >
            <v-select
              id="has_filter"
              v-model="catalog.has_filter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="has_filter"
            />
            <b-form-invalid-feedback v-if="errors && errors.has_filter">
              {{ errors.has_filter[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="catalog.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_h1.label')"
                    :label-for="'meta_h1.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_h1.' + language.code"
                      v-model="catalog.meta_h1[language.code]"
                      :state="errors && errors['meta_h1.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                      {{ errors['meta_h1.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_title.label')"
                    :label-for="'meta_title.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_title.' + language.code"
                      v-model="catalog.meta_title[language.code]"
                      :state="errors && errors['meta_title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                      {{ errors['meta_title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_description.label')"
                    :label-for="'meta_description.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_description.' + language.code"
                      v-model="catalog.meta_description[language.code]"
                      :state="errors && errors['meta_description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                      {{ errors['meta_description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_keywords.label')"
                    :label-for="'meta_keywords.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_keywords.' + language.code"
                      v-model="catalog.meta_keywords[language.code]"
                      :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                      {{ errors['meta_keywords.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.canonical.label')"
                    :label-for="'canonical.' + language.code"
                  >
                    <b-form-input
                      :id="'canonical.' + language.code"
                      v-model="catalog.canonical[language.code]"
                      :state="errors && errors['canonical.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                      {{ errors['canonical.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.description.label')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <TextEditor
                      :id="'description.' + language.code"
                      v-model="catalog.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row
            v-if="$ability.can('update', 'settings')"
            class="mt-2"
          >
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="submitCatalog"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('admin.catalog_filter.title') }}</span>
        </template>
        <b-overlay
          :show="data === null"
          rounded="sm"
        >
          <b-form
            v-if="data"
            class="mt-1"
          >
            <div class="repeater-form">
              <b-row
                v-for="(attribute, index) in data"
                :id="'attribute' + index"
                :key="index"
                ref="row"
              >
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.attribute_id.label')"
                    :label-for="'attributes.' + index + '.attribute_id'"
                    :state="errors && errors[index + '.attribute_id'] ? false : null"
                  >
                    <v-select
                      :id="'attributes.' + index + '.attribute_id'"
                      v-model="attribute.attribute_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniqAttributes"
                      :reduce="val => val.value"
                      :clearable="false"
                      :input-id="'attributes.' + index + '.attribute_id'"
                      @search="searchAttribute"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.attribute_id']">
                      {{ errors[index + '.attribute_id'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    :label-for="'attributes.' + index + '.position'"
                  >
                    <b-form-input
                      :id="'attributes.' + index + '.position'"
                      v-model="attribute.position"
                      :state="errors && errors[index + '.position'] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.position']">
                      {{ errors[index + '.' + '.position'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  :class="`hidden`"
                >
                  <b-form-group
                    :label="$t('form.can_indexing.label')"
                    :label-for="'attributes.' + index + '.can_indexing'"
                  >
                    <b-form-checkbox
                      :id="'attributes.' + index + '.can_indexing'"
                      v-model="attribute.can_indexing"
                      :state="errors && errors[index + '.can_indexing'] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.can_indexing']">
                      {{ errors[index + '.' + '.can_indexing'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  md="4"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeAttribute(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.delete') }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addAttribute"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('general.add') }}</span>
            </b-button>

            <b-row
              v-if="errors && errors.attributes"
            >
              <b-col cols="12">
                <b-form-invalid-feedback :state="false">
                  {{ errors.attributes[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row
              v-if="$ability.can('update', 'settings')"
              class="mt-2"
            >
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="submit"
                >
                  {{ $t('general.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-tab>
      <b-tab :title="$t('admin.navigation.extensions')">
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              :label="$t('form.addExtension.label')"
              label-for="addExtensionId"
            >
              <v-select
                id="addExtensionId"
                v-model="addExtensionId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="extensionOptions"
                :reduce="val => val.value"
                input-id="addExtensionId"
                :searchable="true"
                @search="searchExtension"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              class="mt-2"
              block
              @click="addExtension"
            >
              {{ $t('general.add') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <div class="halo-tree mb-2">
              <draggable
                v-model="catalog.extensions"
                group="tree"
              >
                <div
                  v-for="(extension, index) in catalog.extensions"
                  :key="extension.value"
                  class="li"
                >
                  <div class="tree-node-el">
                    <div>
                      <span>{{ extension.label }}</span>
                    </div>
                    <div>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeExtension(index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="14"
                          class-name="mr-50"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="$ability.can('update', 'settings')"
          class="mt-2"
        >
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="submitCatalog"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import _ from 'lodash'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      languages: [],
      catalog: {
        type: 'promotion',
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        indexing_status: 'enabled',
        has_filter: 'enabled',
        canonical: {},
        extensions: [],
      },
      addExtensionId: null,
      extensionOptions: [],
      data: null,
      attributesOptions: [],
      attributesBaseOptions: [],
    }
  },
  computed: {
    uniqAttributes() {
      return _.uniqBy(_.concat(this.attributesBaseOptions, this.attributesOptions), 'value')
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/admin/extensions', {
      params: {
        per_page: 20,
      },
    })
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http('/api/admin/catalog', { params: { type: 'promotion' } }).then(response => {
      const { data } = response.data
      if (data) {
        this.catalog.indexing_status = data.indexing_status
        this.catalog.has_filter = data.has_filter
        this.catalog.extensions = this.$options.filters.transformForVSelect(data.extensions, 'id', 'title')

        _.each(this.languages, language => {
          const translation = data ? _.find(data.translations, { locale: language.code }) : null

          this.catalog.title[language.code] = translation ? translation.title : null
          this.catalog.description[language.code] = translation ? translation.description : null
          this.catalog.meta_description[language.code] = translation ? translation.meta_description : null
          this.catalog.meta_h1[language.code] = translation ? translation.meta_h1 : null
          this.catalog.meta_keywords[language.code] = translation ? translation.meta_keywords : null
          this.catalog.meta_title[language.code] = translation ? translation.meta_title : null
          this.catalog.canonical[language.code] = translation ? translation.canonical : null
        })
      }
    })

    await this.$http('/api/admin/catalog/filter', { params: { type: 'promotion' } }).then(response => {
      const { data } = response.data

      this.attributesBaseOptions = this.$options.filters.transformForVSelect(_.map(data, d => d.attribute), 'id', 'title')
      this.data = data
    })
  },
  created() {
    if (!Object.keys(this.attributesOptions).length) {
      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 20,
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
  },
  methods: {
    selectedAvatar(data) {
      this.seo.avatar = data
    },
    addAttribute() {
      this.data.push({
        attribute_id: null,
        attribute_value_id: null,
        position: Object.keys(this.data).length + 1,
        can_indexing: false,
      })
    },
    removeAttribute(index) {
      this.data.splice(index, 1)
    },
    async submit() {
      await this.$http.post('/api/admin/catalog/filter/promotion', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async submitCatalog() {
      await this.$http.post('/api/admin/catalog', {
        ...this.catalog,
        ...{ extensions: _.map(this.catalog.extensions, ext => ext.value) },
      })
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async searchExtension(query) {
      await this.$http.get('/api/admin/extensions', {
        params: {
          query,
          per_page: 20,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchAttribute(query) {
      await this.$http.get('/api/attributes', { params: { query, per_page: 20 } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addExtension() {
      this.catalog.extensions.push(_.find(this.extensionOptions, { value: this.addExtensionId }))
      this.addExtensionId = null
    },
    removeExtension(index) {
      this.catalog.extensions.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
